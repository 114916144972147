export default {
    "智能门禁后台管理系统": "Smart Door Access Management System",
    "账户:": "Account",
    "密码:": "Password",
    "验证码:": "Verification Code:",
    "登录": "Login",
    "登录成功": "Login Success",
    "登录失败": "Login Failed",
    "确定": "Confirm",
    "记住密码": "Remember Password",
    "验证码": "Verification Code",
    "首页": "Home",
    "编辑": "Edit",
    "预览": "Preview",
    "设为首页": "Set as Home",
    "其他页面": "Other Page",
    "请输入帐户名": "",
    "请输入密码": "",
    "请输入验证码": "",
    "登录": "Login",
    "登录成功": "Login Success",
    "登录失败": "Login Failed",
    "确定": "Confirm",
    "记住密码": "Remember Password",
    "验证码": "Verification Code",
    "首页": "Home",
    "编辑": "Edit",
    "预览": "Preview",
    "设为首页": "Set as Home",
    "其他页面": "Other Page",
    "设备管理": "Manage Devices",
    "门名称": "Door Identifier",
    "门类型": "Door Type",
    "门状态": "Door Status",
    "公司id": "Company ID",
    "用户管理": "Manage Users",
    "日志管理": "Manage Logs",
    "系统设置": "System Settings",
    "退出": "Logout",
    "添加": "Add",
    "导出": "Export",
    "职务": "Job Title",
    "删除": "Delete",
    "请输入姓名": "",
    "禁用": "Disable",
    "设备名称": "Device Identifier",
    "启用": "Enable",
    "删除成功": "Delete Success",
    "当前选中编号为": "The currently selected number is",
    "数据": "Data",
    "是否确认导出?": "Are you sure you want to export?",
    "此操作将导出当前条件下所有数据而非选中数据": "This operation will export all data under the current conditions rather than selected data",
    "在线": "Online",
    "离线": "Offline",
    "自动门": "Automatic Door",
    "工业门": "Industrial Door",
    "ICCID": "ICCID",
    "到期时间": "Access Expiration",
    "使用时间（开始）": "Usage Start Time",
    "使用时间（结束）": "Usage End Time",
    "上升logo": "Raise Indicator",
    "下降logo": "Lower Indicator",
    "中止logo": "Stop Indicator",
    "在线logo": "Online Indicator",
    "离线logo": "Offline Indicator",
    "添加时间": "Entry Added On",
    "操作": "Actions",
    "4G卡到期状态": "4G Card Expiration Status",
    "未到期": "Not Expired",
    "已到期": "Expired",
    "即将到期": "About to Expire",
    "设备编码": "Device ID",
    "在线时间": "Last Online Timestamp",
    "离线时间": "Last Offline Timestamp",
    "是否在线": "Connection Status",
    "修改": "Modify",
    "查询": "Query",
    "重置": "Reset",
    "共": "Total ",
    "条": " data",
    "页": "Page",
    "页大小": "Page Size",
    "页码": "Page Number",
    "的数据": "data",
    "确认删除所选中数据?": "Are you sure you want to delete the selected data?",
    "请输入门名称": "",
    "请选择到期状态": "",
    "请输入公司名称": "",
    "请输入公司ID": "",
    "请输入公司地址": "",
    "请输入联系方式": "",
    "请输入状态": "",
    "请输入创建时间": "",
    "请输入合约时间": "",
    "请输入合同编号": "",
    "请输入操作": "",
    "请输入启用": "",
    "请输入关闭": "",
    "请输入成功": "",
    "请输入失败": "",
    "请输入更多": "",
    "请输入重置密码": "",
    "公司ID": "Company ID",
    "公司名称": "Company Name",
    "公司地址": "Company Address",
    "联系方式": "Contact Information",
    "状态": "Status",
    "创建时间": "Date Created",
    "新增": "Add",
    "收起": "Collapse",
    "是": "Yes",
    "否": "No",
    "开": "Open",
    "退出登录": "Logout",
    "关": "Close",
    "请选择状态": "",
    "展开": "Expand",
    "确认": "Confirm",
    "的用户吗?": " user?",
    "启用": "Enable",
    "停用": "Disable",
    "成功": "Success",
    "失败": "Failure",
    "用户名称": "User Name",
    "更多": "More",
    "公司Id": "Company Id",
    "选择用户": "Select User",
    "加载中...": "Loading...",
    "加载更多": "Load More",
    "没有更多数据": "No More Data",
    "暂无数据": "No Records Found",
    "公司管理": "Company Management",
    "APP版升级": "APP Version Upgrade",
    "系统管理": "System Management",
    "角色管理": "Role Management",
    "菜单管理": "Menu Management",
    "参数设置": "Parameter Settings",
    "logo管理": "Logo Customization",
    "APP用户管理": "Manage App Users",
    "意见反馈": "Feedback",
    "用户注销管理": "User Logout Management",
    "分组管理": "Manage Groups",
    "nfc信息": "Manage NFC Information",
    "设备操作记录": "Device Activity Log ",
    "退出登录": "Logout",
    "开始使用时间": "Access Start Time",
    "结束使用时间": "Access End Time",
    "停用时间": "Disable Time",
    "上升图片": "Rise Image",
    "下降图片": "Lower Image",
    "停止图片": "Stop Image",
    "在线图片": "Online Image",
    "离线图片": "Offline Image",
    "开门信号时间": "Open Signal Time",
    "停止信号时间": "Stop Signal Time",
    "关门信号时间": "Close Signal Time",
    "上传图片": "Upload Image",
    "请输入开门信号输出时间(秒)": "",
    "请输入停止信号输出时间(秒)": "",
    "请输入关门信号输出时间(秒)": "",
    "请选择停用时间": "",
    "已绑定用户": "Bound User",
    "绑定用户": "Bind User",
    "保存": "Save",
    "取消": "Cancel",
    "姓名": "Full Name",
    "手机号": "Phone Number",
    "部门": "Department",
    "注册时间": "Profile Created On",
    "用户状态": "Account Status",
    "选择所有数据": "Select All Data",
    "选择奇数行": "Select Odd Row",
    "请输入手机号": "",
    "角色名": "Role Name",
    "权限标识": "Permission Identifier",
    "排序": "Sort",
    "脱敏权限": "De-sensitive Permission",
    "数据权限": "Data Permission",
    "分配用户": "Assign User",
    "请输入": "",
    "角色编号": "Role ID",
    "权限字符": "Permission Characters",
    "控制器中定义的权限字符": "Permission characters defined in the controller",
    "显示顺序": "Display Order",
    "菜单权限": "Menu Permission",
    "展开/折叠": "Expand/Collapse",
    "全选/全不选": "Select All/Unselect All",
    "当勾选项为空时方可切换模式，": "When the selected items are empty, the mode can be switched",
    "正常": "Normal",
    "父子联动": "Parent-Child Linkage",
    "修改角色": "Modify Role",
    "超级管理员角色信息 不可更改": "Super administrator role information cannot be changed",
    "合约时间": "contract time",
    "合约编号": "contract number",
    "合同编号": "contract number",
    "重置密码": "reset password",
    "密码": "password",
    "用户名": "User Name",
    "新密码": "new password",
    "确认密码": "confirm password",
    "请输入新密码": "",
    "请输入确认密码": "",
    "请再次输入新密码确认": "",
    "两次输入的密码不一致": "The two passwords do not match",
    "请输入5-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种": "Please enter 5-20 letters, numbers, or symbols (excluding spaces), and at least two of letters, numbers, and symbols",
    "用户密码长度必须介于 5 和 20 之间": "The user password must be between 5 and 20 characters",
    "请确认密码": "",
    "图片": "Image",
    "地址": "Address",
    "地址不能为空": "The address cannot be empty",
    "导入": "Import",
    "工号": "Employee ID",
    "账号": "User Account",
    "头像": "Profile Picture",
    "请输入工号": "",
    "请输入账号": "",
    "请输入职务": "",
    "请输入开始时间": "",
    "请输入结束时间": "",
    "请输入公司Id": "",
    "用户权限": "User Role & Permissions",
    "只可以用NFC开门": "Only NFC can open the door",
    "只可以用APP开门": "Only APP can open the door",
    "无限制": "No Limit",
    "性别": "Gender",
    "保密": "Confidential",
    "男": "Male",
    "女": "Female",
    "是否管理员": "Is Admin",
    "绑定设备": "Bind Device",
    "已绑设备名": "Bound Device Name",
    "生日": "Birthday",
    "备注": "Remark",
    "登陆账号": "Login Account",
    "请输入重置的密码": "",
    "请输入内容": "",
    "请输入开始时间(0-2400)": "",
    "请输入结束时间(0-2400)": "",
    "设备ICCID": "Device ICCID",
    "请输入门类型": "",
    "请输入设备编码": "",
    "请输入设备ICCID": "",
    "nfcId": "NFC ID",
    "nfc名称": "NFC Tag Name",
    "门编码": "Door Code",
    "插入时间": "Date Added",
    "请输入nfcId": "",
    "请输入nfc名称": "",
    "请输入门编码": "",
    "操作类型": "Action Type",
    "操作时间": "Timestamp",
    "用户开门": "User Open",
    "用户关门": "User Close",
    "用户停止": "User Stop",
    "门开到位": "Door Open",
    "门关到位": "Door Close",
    "半开": "Half Open",
    "开门": "Open",
    "停止": "Stop",
    "关门": "Close",
    "分组名称": "Group Name",
    "类型": "Group Type",
    "成员": "Users in Group",
    "设定允许进门开始时间": "Entry Start Time",
    "设定允许进门结束时间": "Entry End Time",
    "用户昵称": "User Nickname",
    "公司编号": "Company ID",
    "请输入类型": "",
    "请输入成员": "",
    "用户": "User",
    "设备": "Device",
    "请选择用户": "",
    "请选择用户分组": "",
    "请选择设备": "",
    "新增分组": "Add Group",
    "修改分组": "Modify Group",
    "获取分组信息失败，请检查网络或重试": "Failed to get group information, please check the network or try again",
    "修改成功": "Modify Success",
    "新增成功": "Add Success",
    "操作失败": "Operation Failed",
    "请输入分组名称": "",
    "早上好": "Good Morning",
    "上午好": "Good Morning",
    "中午好": "Good Afternoon",
    "下午好": "Good Afternoon",
    "晚上好": "Good Evening",
    "休息一会儿吧": "Take a break",
    "准备吃什么呢?": "What's for lunch/dinner?",
    "要不要打一把 DOTA": "Want to play DOTA?",
    "我猜你可能累了": "You might be tired",
    "个人中心": "Personal Center",
    "个人设置": "Personal Settings",
    "提示": "Tips",
    "确定注销并退出系统吗？": "Are you sure you want to log out of the system?",
    "条":"data"
}
